import { LitElement, html, css, PropertyValues } from "lit";
import { state, property } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { connect } from "../../../store/index.js";

import { DateFormatter } from "../../../formatting/dateformats.js";
import { money } from "../../../formatting/numberformats.js";
import { cprNumber } from "../../../formatting/piiformats.js";
import "./create-person-view.js";
import router from "../../../router/index.js";
import { get } from "../../../api/client.js";
import { Client, Custody, User } from "../../../types/types.js";

declare global {
	interface HTMLElementTagNameMap {
		"customer-custodies-view": typeof ConnectedElement;
	}
}

// User with Custodies
type Person = Partial<User> & { custodies: Custody[] };

export class CustomerCustodiesView extends LitElement {
	@property({ type: Number })
	accessor clientId: number | undefined;

	@state()
	persons: Person[] = [];

	@state()
	userId?: number;

	@state()
	totalValue: number = 0;

	@state()
	showPII?: boolean = undefined;

	@state()
	openCreatePersonDialog: boolean = false;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`#parms {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
	  }

	  #persons tr.first td {
		border-top: 2px solid #ddd;
	  }

	  div.actions {
		display: flex;
		flex-grow: 0;
		flex-direction: row;
		margin-left: auto;
		align-items: flex-start;
	  }

	  div.card-header {
		align-items: flex-start;
	  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <h1>Oversigt</h1>
          <div class="actions">
            <fm-button-v2
              class="button-small"
              type="button"
              @click="${this.createClick}"
              >Opret ny person</fm-button-v2
            >
          </div>
        </div>
        <table id="persons">
          <thead class="sticky">
            <tr>
              <th>Navn</th>
              <th>CPR/CVR/SE</th>
              <th>Kontonummer</th>
              <th>Type</th>
              <th>Kontonavn</th>
              <th>Modelportefølje</th>
              <th>Oprettet</th>
              <th class="numeric">Værdi</th>
            </tr>
          </thead>
          <tbody>
            ${this.persons.map(
							(person) => html` <tr class="first">
                  <td
                    valign="top"
                    rowspan="${
											person.custodies.length === 0
												? 1
												: person.custodies.length + 1
										}"
                  >
                    <router-link
                      to="/customers/${this.clientId}/persons/${person.id}"
                      ><a>${person.name}</a></router-link
                    >
                  </td>
                  <td
                    valign="top"
                    colspan="${person.custodies.length === 0 ? 7 : 1}"
                    rowspan="${
											person.custodies.length === 0
												? 1
												: person.custodies.length + 1
										}"
                  >
                    ${person.cpr ? cprNumber(person.cpr) : person.cvr}
                  </td>
                </tr>
                ${person.custodies.map(
									(custody, index) => html`
                    <tr class="${index === 0 ? "first" : ""}">
                      <td style="white-space: nowrap;">
                        <router-link
                          to="/customers/${custody.client_id}/persons/${
										custody.owner_id
									}/custodies/${custody.id}"
                        >
                          <a>${custody.custody_code}</a>
                        </router-link>
                      </td>
                      <td>${custody.custodytype_code}</td>
                      <td>${custody.name}</td>
                      <td>${custody.model_name} ${custody.model_status}</td>
                      <td style="white-space: nowrap;">
                        ${DateFormatter.format(
													new Date(custody.reg_date),
													"dd-mm-yyyy",
												)}
                      </td>
                      <td class="numeric">${money(custody.currentvalue)}</td>
                    </tr>
                  `,
								)}`,
						)}
            <tr>
              <td colspan="7"><strong>I alt</strong></td>
              <td class="numeric">
                <strong>${money(this.totalValue)}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <fm-dialog
        .opened="${this.openCreatePersonDialog}"
        @close="${() => {
					this.openCreatePersonDialog = false;
				}}"
      >
        ${
					this.openCreatePersonDialog
						? html`<create-person-view
              @created="${this.personCreated}"
              .clientId="${this.clientId}"
            ></create-person-view>`
						: null
				}
      </fm-dialog>
    `;
	}

	async updated(changedProperties: PropertyValues<this>) {
		if (changedProperties.has("clientId")) {
			// Reset list of persons and total value when clientId changes
			this.persons = [];
			this.totalValue = 0;
			this.requestUpdate();

			const clientResponse = await get<Client>(`/clients/${this.clientId}`);
			if (!clientResponse.ok) {
				return;
			}
			const client: Client = clientResponse.value;

			const usersResponse = await get<{ data: User[] }>(
				`/users?client_id=${this.clientId}`,
			);
			if (!usersResponse.ok) {
				return;
			}
			const users = usersResponse.value.data;

			const persons: Person[] = [];
			for (const user of users) {
				// Skip if user_id is set and does not match
				if (this.userId && user.id !== this.userId) continue;

				const person: Person = {
					...user,
					custodies: [],
				};

				persons.push(person);
			}

			const custodyPromises: Promise<Custody>[] = [];

			if (client.custodies) {
				for (const _custody of client.custodies) {
					const custodyPromise: Promise<Custody> = new Promise(
						// rome-ignore lint/suspicious/noAsyncPromiseExecutor: Intentional
						async (resolve, reject) => {
							const custodyDataResponse = await get<{ data: Custody }>(
								`/custodies/${_custody.id}`,
							);

							if (!custodyDataResponse.ok) {
								reject();
								return;
							}

							resolve(custodyDataResponse.value.data);
						},
					);
					custodyPromises.push(custodyPromise);
				}
			}

			const custodies = await Promise.all(custodyPromises);

			// TODO: Not optimal, but it works
			for (const custody of custodies) {
				for (const person of persons) {
					if (
						person.id === custody.owner_id ||
						person.id === custody.owner2_id
					) {
						person.custodies.push(custody);
						this.totalValue += custody.currentvalue ? custody.currentvalue : 0;
					}
				}
			}

			this.persons = persons;

			this.requestUpdate();
		}
	}

	createClick() {
		this.openCreatePersonDialog = true;
	}

	async personCreated(event: CustomEvent) {
		this.openCreatePersonDialog = false;

		router.push(
			`/customers/${event.detail.clientId}/persons/${event.detail.userId}`,
		);
	}
}

const ConnectedElement = connect(CustomerCustodiesView, {
	selectors: (state) => ({
		showPII: state.showPII,
	}),
}) as typeof CustomerCustodiesView;

customElements.define("customer-custodies-view", ConnectedElement);

export default ConnectedElement;

import { LitElement, html, css } from "lit";
import { get } from "../../api/client.js";
import store, { connect } from "../../store/index.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";

import { setTitle } from "../../store/actions.js";
import { sort, toCsv, toast } from "../../utils.js";
import { money } from "../../formatting/numberformats.js";
import { cprNumber } from "../../formatting/piiformats.js";
import "./create-custody-view.js";
import { state } from "lit/decorators.js";
import { CustodiesCustody } from "../../types/types.js";

declare global {
	interface HTMLElementTagNameMap {
		"custodies-view": typeof ConnectedElement;
	}
}

export class CustodiesView extends LitElement {
	@state()
	custodies: CustodiesCustody[] = [];

	@state()
	custodiesSum: number = 0;

	@state()
	showPII?: boolean = undefined;

	@state()
	openCreateCustodyDialog: boolean = false;

	@state()
	loading: boolean = false;

	@state()
	query: string = "";

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`.card {
    padding: 12px;
  }

  #parms {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
  }

  div.search {
    display: flex;
    flex-direction: column;
  }

  div.search div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  div.actions {
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-left: auto;
    align-items: flex-start;
  }

  table.sums th {
    background-color: #f2f2f2;
    text-align: right;
  }`,
	];

	render() {
		return html`
      <div class="card">
        <fm-form
          id="parms"
          class="reportform no-print"
          @submit="${this.refresh}"
        >
          <div class="search">
            <div class="query">
              <input
                type="search"
                class="search-input-big"
                name="query"
                placeholder="Søg efter kontonr., navn, el.lign."
                .value="${this.query}"
                @input="${(e: Event) => {
									this.query = (e.target as HTMLInputElement).value;
								}}"
              />

              <fm-button-v2 id="submit_button" type="submit" class="button submit" .loading="${
								this.loading
							}"
                >Søg</fm-button-v2
              >
              <fm-button-v2
                id="download_button"
                class="button"
                @click="${this.download}"
                ?disabled="${this.custodies.length === 0}"
                >Download</fm-button-v2
              >
            </div>
          </div>
          <div class="actions">
            <fm-button-v2
              class="button-small"
              type="button"
              @click="${this.createClick}"
              >Opret ny</fm-button-v2
            >
          </div>
        </fm-form>

        ${
					!this.loading && this.custodies.length > 0
						? html`<table class="tight sums">
              <tr>
                <th class="numeric">TOTAL: ${money(this.custodiesSum)}</th>
              </tr>
            </table>`
						: null
				}
        <table class="tight">
          <thead class="sticky">
            <tr>
              <th>Kontonummer</th>
              <th data-key="owner_name" @click="${this.sortClick}">Navn</th>
              <th data-key="custodytype_code" @click="${this.sortClick}">
                Type
              </th>
              <th data-key="name" @click="${this.sortClick}">Kontonavn</th>
              <th data-key="model_name" @click="${this.sortClick}">
                Modelportefølje
              </th>
              <th data-key="publicid" @click="${this.sortClick}">
                CPR/CVR/SE
              </th>
              <th data-key="reg_date" @click="${this.sortClick}">
                Oprettet
              </th>
              <th data-key="groups" @click="${this.sortClick}">
                Kundegruppe
              </th>
              <th
                class="numeric"
                data-key="currentvalue"
                @click="${this.sortClick}"
              >
                Værdi
              </th>
            </tr>
          </thead>
          <tbody>
            ${
							this.loading
								? html`<tr><td colspan="9">Indlæser investeringskonti...</td></tr>`
								: this.custodies.map(
										(custody) => html`
                <tr>
                  <td style="white-space: nowrap;">
                    <router-link
                      to="/customers/${custody.client_id}/persons/${
											custody.owner_id
										}/custodies/${custody.custody_id}"
                    >
                      <a>${custody.custody_code}</a>
                    </router-link>
                  </td>
                  <td>
                    ${custody.owner_name.split("/").map(
											(owner_name, idx) =>
												html`${
													idx === 0
														? html`<router-link
                              to="/customers/${custody.client_id}/persons/${custody.owner_id}"
                              ><a>${owner_name}</a></router-link
                            >`
														: html` /
                              <router-link
                                to="/customers/${custody.client_id}/persons/${custody.owner2_id}"
                                ><a>${owner_name}</a></router-link
                              >`
												}`,
										)}
                  </td>
                  <td>${custody.custodytype_code}</td>
                  <td>${custody.name}</td>
                  <td>${custody.model_name} ${custody.model_status}</td>
                  <td style="white-space: nowrap;">
                    ${
											custody.publicid
												? custody.publicid
														.split("/")
														.map(
															(id, idx) =>
																html`${idx > 0 ? "/" : null}${cprNumber(id)}`,
														)
												: null
										}
                  </td>
                  <td style="white-space: nowrap;">
                    ${
											custody.reg_date
												? custody.reg_date.replaceAll("-", ".")
												: ""
										}
                  </td>
                  <td>${custody.groups}</td>
                  <td class="numeric">${money(custody.currentvalue)}</td>
                </tr>
              `,
								  )
						}
          </tbody>
        </table>
        ${
					!this.loading && this.custodies.length > 0
						? html`<table class="tight sums">
              <tr>
                <th class="numeric">TOTAL: ${money(this.custodiesSum)}</th>
              </tr>
            </table>`
						: null
				}
      </div>
      <fm-dialog
        .opened="${this.openCreateCustodyDialog}"
        @close="${() => {
					this.openCreateCustodyDialog = false;
				}}"
      >
        ${
					this.openCreateCustodyDialog
						? html`<create-custody-view
              id="create-custody-view"
              @created="${this.custodyCreated}"
            ></create-custody-view>`
						: null
				}
      </fm-dialog>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();
		store.dispatch(setTitle("Investeringskonti"));
	}

	createClick() {
		this.openCreateCustodyDialog = true;
	}

	async custodyCreated() {
		this.openCreateCustodyDialog = false;

		await this.search("");
	}

	async search(query: string) {
		this.loading = true;

		const custodiesResponse = await get<{ data: CustodiesCustody[] }>(
			`/custodies?query=${query}`,
		);

		if (!custodiesResponse.ok) {
			const error = await custodiesResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		this.custodies = custodiesResponse.value.data;

		let custodiesSum = 0;
		for (let i = 0; i < this.custodies.length; i++) {
			custodiesSum += this.custodies[i].currentvalue
				? this.custodies[i].currentvalue
				: 0;
		}
		this.custodiesSum = custodiesSum;
		this.loading = false;
	}

	async refresh() {
		this.loading = true;
		await this.search(this.query);
		this.loading = false;
	}

	sortClick(event: Event) {
		const key = (event.target as HTMLTableCellElement).getAttribute("data-key");

		if (!key) {
			return;
		}

		this.custodies = sort(this.custodies, key) as CustodiesCustody[];
		this.requestUpdate();
	}

	async download(event: Event) {
		this.loading = true;
		const csvHeaders = [
			"custody_code",
			"owner_name",
			"custodytype_code",
			"name",
			"model_name",
			"model_status",
			"currentvalue",
			"client_phone",
			"client_email",
			"groups",
		];
		const csvFields = [
			"custody_code",
			"owner_name",
			"custodytype_code",
			"name",
			"model_name",
			"model_status",
			"currentvalue",
			"client_phone",
			"client_email",
			"groups",
		];

		let txtdata = toCsv(csvHeaders, csvFields, this.custodies);

		try {
			txtdata = btoa(txtdata);
		} catch (err) {
			// remove n@ascii
			txtdata = btoa(unescape(encodeURIComponent(txtdata)));
		}

		const a = document.createElement("a");
		a.setAttribute("href", `data:text/csv;base64,${txtdata}`);
		a.setAttribute("download", "fm_konti.csv");
		a.click();
		this.loading = false;
	}
}

const ConnectedElement = connect(CustodiesView, {
	selectors: (state) => ({
		showPII: state.showPII,
	}),
}) as typeof CustodiesView;

export default ConnectedElement;

customElements.define("custodies-view", ConnectedElement);

import { ToastElement } from "@fundmarket/web-components";
import { money, quantity } from "./formatting/numberformats.js";

export function toast(message: string, duration = 3000) {
	return ToastElement.create(message, duration);
}

export function toCsv(
	headers: string[],
	fields: string[],
	data: Record<string, unknown>[],
) {
	let csv = headers.map((s) => `"${s}"`).join(";") + "\n";
	for (let i = 0; i < data.length; i++) {
		csv = csv + fields.map((f) => `"${data[i][f] || ""}"`).join(";") + "\n";
	}
	return csv;
}

// string, money, quantity
export type Formats = "s" | "m" | "q";

export function toCsvF(
	headers: string[],
	fields: string[],
	formats: Formats[],
	data: Record<string, unknown>[],
) {
	let csv = headers.map((s) => `"${s}"`).join(";") + "\n";
	for (let i = 0; i < data.length; i++) {
		for (let j = 0; j < fields.length; j++) {
			switch (formats[j]) {
				case "m":
					csv = `${csv}${money(Number(data[i][fields[j]]))};`;
					break;
				case "q":
					csv = `${csv}${quantity(Number(data[i][fields[j]]))};`;
					break;
				default:
					csv = `${csv}"${data[i][fields[j]]}";`;
			}
		}
		csv = csv + "\n";
	}
	return csv;
}

// TODO: Move code dependant of Date.addDays to use addDays() instead
declare global {
	interface Date {
		addDays(days: number): Date;
	}
}

Date.prototype.addDays = function (days) {
	const date = new Date(this.valueOf());
	date.setDate(date.getDate() + days);
	return date;
};

export function addDays(date: Date, days: number): Date {
	const result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}

const keys = new WeakMap();
export function sort(array: Record<string, unknown>[], key: string) {
	if (key === keys.get(array)) {
		// already sorted, reverse
		return array.reverse();
	} else {
		// not yet sorted
		keys.set(array, key);
		const isString = typeof array[0][key] === "string";
		return array.sort((a, b) => {
			let A = a[key] || "";
			let B = b[key] || "";
			if (isString) {
				A = (A as string).toLowerCase();
				B = (B as string).toLowerCase();
			}
			return A > B ? 1 : B > A ? -1 : 1;
		});
	}
}

import { LitElement, html, css } from "lit";
import { state, property, customElement } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";
import { styles as uploadStyles } from "../../../styles/upload.js";
import { toast } from "../../../utils.js";
import { post } from "../../../api/client.js";

declare global {
	interface HTMLElementTagNameMap {
		"upload-file-view": typeof UploadFileView;
	}
}

@customElement("upload-file-view")
export default class UploadFileView extends LitElement {
	@property({ type: Number })
	accessor userId: number | undefined;

	@state()
	loading: boolean = false;

	@state()
	file: {
		filename: string;
		filetype: string;
		content: string;
	} = {
		filename: "",
		filetype: "",
		content: "",
	};

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		uploadStyles,
		css`div.overlay {
	display: flex;
	flex-direction: column;
	gap: 16px;
  }`,
	];

	render() {
		return html`
      <div class="overlay">
        <h1>Upload legitimation</h1>
        <fm-form id="form" class="form-grid">
          <label class="form-field">
            <input type="file" id="file" @change="${this.onFileChange}" />
          </label>
          <fm-button-v2 id="submit_button" type="submit" class="btn" ?disabled=${
						this.file.filename === ""
					} .loading="${this.loading}" @click="${this.onSubmit}">
            Upload
          </fm-button-v2>
        </fm-form>
      </div>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();
	}

	fileRead(f: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.addEventListener("loadend", (e: ProgressEvent<FileReader>) => {
				if (e.target?.result) {
					this.file.content = e.target.result.toString();
					resolve("");
				} else {
					reject("Error reading file");
				}
			});
			reader.readAsDataURL(f);
		});
	}

	async onFileChange(event: Event) {
		event.preventDefault();

		const target = event.target as HTMLInputElement;
		if (!target.files || !target.files.length) {
			return;
		}

		const file = target.files[0];
		this.file.filename = file.name;
		this.file.filetype = file.type;
		await this.fileRead(file);
	}

	async onSubmit(event: Event) {
		this.loading = true;
		await post<typeof this.file, { status: string; message: string }>(
			`/users/${this.userId}/file`,
			this.file,
		);
		this.loading = false;

		this.dispatchEvent(new CustomEvent("uploaded"));
		toast("Fil uploadet");
	}
}

import { LitElement, html, css, PropertyValues } from "lit";
import { property, state } from "lit/decorators.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import { get } from "../../api/client.js";
import store, { connect } from "../../store/index.js";
import {
	setTitle,
	setClientData,
	setUserData,
	setCustodyData,
} from "../../store/actions.js";
import router from "../../router/index.js";
import { Client, Custody, User } from "../../types/types.js";

declare global {
	interface HTMLElementTagNameMap {
		"customers-container-view": typeof ConnectedElement;
	}
}

export class CustomersContainerView extends LitElement {
	@property({ type: Number })
	accessor clientId: number | undefined;

	@property({ type: Number })
	accessor userId: number | undefined;

	@property({ type: Number })
	accessor custodyId: number | undefined;

	@property({ type: String })
	accessor area: "customers" | "customer" | "person" | "custody" | undefined;

	@state()
	client: Client | null = null;

	@state()
	user: User | null = null;

	@state()
	custody: Custody | null = null;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`select {
		background: var(--primary-color);
		color: white;
		border: none;
		font-weight: 500;
		font-family: Roboto, sans-serif;
	  }

	  select:focus {
		outline: 0;
	  }

	  ul.action-links li > label {
		background: var(--primary-color);
		padding: 8px 8px;
	  }

	  ul.action-links option:first-child {
		text-align: center;
	  }

	  ul.action-links li:last-child {
		margin-right: 0px;
		padding-right: 0px;
	  }

	  header.horizontal-menu-header {
		position: sticky;
		top: 0px;
	  }`,
	];

	async connectedCallback() {
		super.connectedCallback();

		store.dispatch(setTitle("Kunder"));
	}

	async updated(changedProperties: PropertyValues<this>) {
		const promises: Promise<void>[] = [];

		if (changedProperties.has("clientId")) {
			if (!this.clientId) {
				store.dispatch(setClientData(null));
			} else {
				// rome-ignore lint/suspicious/noAsyncPromiseExecutor: Intentional
				const clientPromise = new Promise<void>(async (resolve, reject) => {
					const client = await get<Client>(`/clients/${this.clientId}`);

					if (!client.ok) {
						return;
					}

					store.dispatch(setClientData(client.value));

					resolve();
				});
				promises.push(clientPromise);
			}
		}

		if (changedProperties.has("userId")) {
			if (!this.userId) {
				store.dispatch(setUserData(null));
			} else {
				// rome-ignore lint/suspicious/noAsyncPromiseExecutor: Intentional
				const userPromise = new Promise<void>(async (resolve, reject) => {
					const user = await get<{ data: User }>(`/users/${this.userId}`);

					if (!user.ok) {
						return;
					}

					store.dispatch(setUserData(user.value.data));

					resolve();
				});
				promises.push(userPromise);
			}
		}

		if (changedProperties.has("custodyId")) {
			if (!this.custodyId) {
				store.dispatch(setCustodyData(null));
			} else {
				// rome-ignore lint/suspicious/noAsyncPromiseExecutor: Intentional
				const custodyPromise = new Promise<void>(async (resolve, reject) => {
					const custody = await get<{ data: Custody }>(
						`/custodies/${this.custodyId}`,
					);

					if (!custody.ok) {
						return;
					}

					store.dispatch(setCustodyData(custody.value.data));

					resolve();
				});
				promises.push(custodyPromise);
			}
		}

		if (promises.length === 0) {
			return;
		}

		await Promise.all(promises);

		this.requestUpdate();
	}

	render() {
		return html`
      <header class="horizontal-menu-header">
        ${this.renderCustomerMenu()}
      </header>
      <slot></slot>
    `;
	}

	renderCustomerMenu() {
		if (!this.area) {
			return null;
		}

		return html`
      <div class="secondary">
        <div>
          <ul class="menu-links">
            ${
							this.clientId && this.client?.id
								? html`<li>
                  <div class="tiny">Engagement</div>
                  <router-link to="/customers/${this.clientId}" exact>
                    <a class=${this.area === "customer" ? "active" : ""}
                      >${this.client?.name}</a
                    ></router-link
                  >
                </li>`
								: null
						}
            ${
							this.userId && this.user?.id
								? html`<li>
                    <div class="tiny">Person</div>
                    <router-link
                      to="/customers/${this.clientId}/persons/${this.userId}"
                      exact
                      ><a class="${this.area === "person" ? "active" : ""}"
                        >${this.user?.name}</a
                      ></router-link
                    >
                  </li>
                  ${
										this.custodyId && this.custody?.id
											? html`<li>
                        <div class="tiny">Investeringskonto</div>
                        <div>
                          <router-link
                            to="/customers/${this.clientId}/persons/${
													this.userId
											  }/custodies/${this.custodyId}"
                            exact
                            ><a class=${this.area === "custody" ? "active" : ""}
                              >${this.custody?.name}</a
                            ></router-link
                          >
                          (${this.custody?.custody_code})
                        </div>
                      </li>`
											: null
									} `
								: null
						}
          </ul>
        </div>

        <div class="actions">
          <ul class="action-links">
            ${
							this.area === "customers"
								? html`<li>
                  <label for="reports">
                    <select id="reports" @change="${this.report}">
                      <option value="">RAPPORTER</option>
                      <option disabled>----------------------</option>
                      <option value="questions">AML spørgsmål</option>
                      <option value="portfolio-tax">
                        Porteføljeskatterapport
                      </option>
                      <option value="send-periodics">
                        Send perioderapporter
                      </option>
                    </select>
                  </label>
                </li>`
								: this.area === "customer"
								? html`<li>
                  <label for="reports">
                    <select id="reports" @change="${this.report}">
                      <option value="">RAPPORTER</option>
                      <option disabled>----------------------</option>
                      <option value="positions">Afkast</option>
                      <option value="questions">AML spørgsmål</option>
                      <option value="periodic">Perioderapport</option>
                    </select>
                  </label>
                </li>`
								: this.area === "person"
								? html`<li>
                  <label for="reports">
                    <select id="reports" @change="${this.report}">
                      <option value="">RAPPORTER</option>
                      <option disabled>----------------------</option>
                      <option value="tax-form">Selvangivelse</option>
                    </select>
                  </label>
                </li>`
								: this.area === "custody"
								? html`<li>
                  <label for="reports">
                    <select id="reports" @change="${this.report}">
                      <option value="">RAPPORTER</option>
                      <option disabled>----------------------</option>
                      <option value="statement">Kontoudtog</option>
                      <option value="tax">Skatterapport</option>
                    </select>
                  </label>
                </li>`
								: null
						}
          </ul>
        </div>
      </div>
    `;
	}

	report(event: Event) {
		const report = (event.target as HTMLSelectElement)?.value;

		if (!report) {
			return;
		}

		// If the user views a report and then a second one,
		// we use this regex to remove the previous report from the path
		const basePathRegEx = /.*[0-9]+/;
		let basePath = "/customers";

		if (basePathRegEx.test(window.location.pathname)) {
			const parts = basePathRegEx.exec(window.location.pathname);
			basePath = parts ? parts[0] : basePath;
		}

		router.push(`${basePath}/${report}`);
	}
}

const ConnectedElement = connect(CustomersContainerView, {
	selectors: (state) => ({
		client: state.clientData,
		user: state.userData,
		custody: state.custodyData,
	}),
}) as typeof CustomersContainerView;

customElements.define("customers-container-view", ConnectedElement);

export default ConnectedElement;

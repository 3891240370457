import { LitElement, html } from "lit";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import { customElement, property, state } from "lit/decorators.js";
import {
	Agreement,
	AgreementDocument,
	AgreementDocumentFile,
} from "../../types/types.js";

import { get, put } from "../../api/client.js";
import store from "../../store/index.js";
import { setTitle } from "../../store/actions.js";

import "./upload-agreement-file-view.js";
import { toast } from "../../utils.js";
import { live } from "lit/directives/live.js";

declare global {
	interface HTMLElementTagNameMap {
		"agreement-edit-view": AgreementEditView;
	}
}

@customElement("agreement-edit-view")
export default class AgreementEditView extends LitElement {
	@property({ type: Number })
	accessor agreementId: number | undefined;

	@state()
	agreement?: Agreement;

	@state()
	documents: AgreementDocument[] = [];

	@state()
	openUploadAgremeentFileDialog: boolean = false;

	@state()
	clientaccountId?: number;

	@state()
	userComment: string = "";

	async connectedCallback() {
		super.connectedCallback();
		store.dispatch(setTitle("Kundeaftaler"));

		if (this.agreementId) {
			const getAgreementResponse = await get<{ data: Agreement }>(
				`/agreements/${this.agreementId}`,
			);

			if (!getAgreementResponse.ok) {
				const error = await getAgreementResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl");
				}
				return;
			}

			this.agreement = getAgreementResponse.value.data;

			if (this.agreement) {
				this.clientaccountId = this.agreement.clientaccount_id;
				this.userComment = this.agreement.user_comment || "";
			}

			await this.getAgreementDocuments();
		}
	}

	static styles = [sharedStyles, tableStyles, inputStyles];

	render() {
		return this.agreement
			? html`
      <div class="card">
        <div class="card-header">
          <h1>Kundeaftale</h1>
        </div>
        <fm-form id="form" class="form-grid" url="/agreements/" method="put">
          <label class="form-field">
            Kunde
            <fm-autocomplete
              name="clientaccount_id"
              url="/lov/clientaccounts"
              .value="${{
								id: this.agreement.clientaccount_id || "",
								name: this.agreement.client_name || "",
							}}"
              display="name"
              select="id"
              @select="${this.onClientSelect}"
            >
            </fm-autocomplete>
          </label>
          <label class="form-field">
            Beskrivelse
            <input
              type="text"
              name="user_comment"
              .value="${live(this.userComment)}"
			  @input="${(e: Event) => {
					this.userComment = (e.target as HTMLInputElement).value;
				}}"
            />
          </label>
          <fm-button-v2 class="btn btn--light" type="button" @click="${
						this.save
					}">
            Save
          </fm-button-v2>
          <label class="form-field">
            Oprettet
            <input type="text" disabled readonly value="${
							this.agreement.reg_date || ""
						}" />
          </label>
          <label class="form-field">
            Oprettet af
            <input type="text" disabled readonly value="${
							this.agreement.reg_user || ""
						}" />
          </label>
          <label class="form-field">
            Ændret
            <input type="text" disabled readonly value="${
							this.agreement.mod_date || ""
						}" />
          </label>
          <label class="form-field">
            Ændret af
            <input type="text" disabled readonly value="${
							this.agreement.mod_user || ""
						}" />
          </label>
        </fm-form>
      </div>
      <div class="card">
        <div class="card-header">
          <h2>Aftaledokumenter</h2>
          <div class="actions">
            <fm-button-v2
              class="button-small"
              type="button"
              @click="${this.createClick}"
              >Upload aftaledokument</fm-button-v2
            >
          </div>
        </div>
        ${this.renderDocuments()}
      </div>
      <fm-dialog
        .opened="${this.openUploadAgremeentFileDialog}"
        @close="${() => {
					this.openUploadAgremeentFileDialog = false;
				}}"
      >
        ${
					this.openUploadAgremeentFileDialog
						? html`<upload-agreement-file-view
              .agreementId="${this.agreementId}"
              @uploaded="${this.agreementFileUploaded}"
            ></upload-agreement-file-view>`
						: null
				}
      </fm-dialog>
    `
			: null;
	}

	renderDocuments() {
		return html`
      <table>
        <thead>
          <tr>
            <th>Navn</th>
            <th>Kommentar</th>
            <th>Fundmarket kommentar</th>
            <th>Uploaded</th>
            <th>Uploaded af</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          ${this.documents.map(
						(document) => html`
              <tr>
                <td>${document.filename}</td>
                <td>${document.user_comment}</td>
                <td>${document.adm_comment}</td>
                <td>${document.reg_date}</td>
                <td>${document.reg_user}</td>
                <td>
                  <fm-button-v2
                    class="btn btn--light button-small"
                    type="button"
                    data-document-id="${document.id}"
                    @click="${this.download}"
                  >
                    Download
                  </fm-button-v2>
                </td>
              </tr>
            `,
					)}
        </tbody>
      </table>
    `;
	}

	onClientSelect(event: CustomEvent) {
		const clientaccountIdString = (event.target as HTMLInputElement)?.value;
		this.clientaccountId = parseInt(clientaccountIdString) || undefined;
	}

	async download(event: Event) {
		event.preventDefault();
		const id = (event.target as HTMLButtonElement)?.getAttribute(
			"data-document-id",
		);

		if (!id) {
			return;
		}

		const getAgreementDocumentFileResponse = await get<{
			data: AgreementDocumentFile;
		}>(`/agreements/documents/${id}`);

		if (!getAgreementDocumentFileResponse.ok) {
			const error = await getAgreementDocumentFileResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl");
			}
			return;
		}

		const a = document.createElement("a");
		a.href = getAgreementDocumentFileResponse.value.data.content;
		a.download = getAgreementDocumentFileResponse.value.data.filename;
		a.click();
	}

	async save(event: Event) {
		event.preventDefault();

		if (!this.clientaccountId) {
			toast("FEJL: Ingen kunde valgt");
			return;
		}

		const newAgreementData = {
			clientaccount_id: this.clientaccountId,
			user_comment: this.userComment,
		};

		const updateAgreementResponse = await put<
			{ clientaccount_id: number; user_comment: string },
			{ data: Agreement }
		>(`/agreements/${this.agreementId}`, newAgreementData);

		if (!updateAgreementResponse.ok) {
			const error = await updateAgreementResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl");
			}
			return;
		}

		this.agreement = updateAgreementResponse.value.data;

		toast("Ændringerne er gemt");
	}

	createClick() {
		this.openUploadAgremeentFileDialog = true;
	}

	async getAgreementDocuments() {
		const getAgreementDocumentsResponse = await get<{
			data: AgreementDocument[];
		}>(`/agreements/${this.agreementId}/documents`);

		if (!getAgreementDocumentsResponse.ok) {
			const error = await getAgreementDocumentsResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl");
			}
			return;
		}

		this.documents = getAgreementDocumentsResponse.value.data;
	}

	async agreementFileUploaded(event: CustomEvent) {
		this.openUploadAgremeentFileDialog = false;

		await this.getAgreementDocuments();

		toast("Aftale uploadet");
	}
}

import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";

import store from "../../store/index.js";
import { setTitle } from "../../store/actions.js";
import router from "../../router/index.js";
import { sort, toCsv, toast } from "../../utils.js";
import { money } from "../../formatting/numberformats.js";
import { date } from "../../formatting/dateformats.js";
import { Custody, Order } from "../../types/types.js";
import { get } from "../../api/client.js";

declare global {
	interface HTMLElementTagNameMap {
		"orders-view": typeof OrdersView;
	}
}

@customElement("orders-view")
export default class OrdersView extends LitElement {
	@state()
	orders: Order[] = [];

	@state()
	fromDate: string = date(new Date().addDays(-10));

	@state()
	toDate: string = date(new Date());

	@state()
	query: string = "";

	@state()
	group: string = "";

	@state()
	loading: boolean = false;

	@state()
	downloading: boolean = false;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`fm-form {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .card {
    padding: 12px;
  }

  div.actions {
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-left: auto;
  }

  table.sums {
    display: flex;
    justify-content: flex-end;
  }

  table.sums tr td {
    background-color: #f2f2f2;
    border-bottom: none;
  }

  /* Override default that router-links are hidden in print */
  @media print {
    router-link {
      display: initial;
    }
  }`,
	];

	render() {
		return html`
      <div class="card">
        <fm-form
          id="parms"
          class="reportform no-print"
          method="get"
          @submit="${this.refresh}"
        >
          <div class="form-space">
            <label for="fromdate">Fra</label>
            <input
              type="date"
              id="fromdate"
              name="fromdate"
              value="${this.fromDate}"
              @input="${(e: Event) => {
								this.fromDate = (e.target as HTMLInputElement).value;
							}}"
            />
          </div>
          <div class="form-space">
            <label for="todate">Til</label>
            <input
              type="date"
              id="todate"
              name="todate"
              value="${this.toDate}"
              @input="${(e: InputEvent) => {
								this.toDate = (e.target as HTMLInputElement).value;
							}}"
            />
          </div>
          <div class="form-space">
            <label for="query">Søg</label>
            <input
              type="search"
              class="search-input"
              id="query"
              name="query"
              placeholder="Kontonr., navn, el.lign."
              @input="${(e: InputEvent) => {
								this.query = (e.target as HTMLInputElement).value;
							}}"
            />
          </div>
          <div class="form-space">
            <label for="group">Kundegruppe</label>
            <input
              type="search"
              class="search-input"
              id="group"
              name="group"
              placeholder="Kundegruppe"
              @input="${(e: InputEvent) => {
								this.group = (e.target as HTMLInputElement).value;
							}}"
            />
          </div>
          <div class="form-space">
            <fm-button-v2 id="submit_button" type="submit" class="button submit" .loading="${
							this.loading
						}"
              >Søg</fm-button-v2
            >
          </div>
          <div class="form-space">
            <fm-button-v2
              id="download_button"
              class="button"
              @click="${this.download}"
              ?disabled="${this.orders.length === 0}"
              .loading="${this.downloading}"
              >Download</fm-button-v2
            >
          </div>
        </fm-form>

        ${
					!this.loading && this.orders.length > 0
						? html`${this.renderSum()}

        <table class="tight">
          <thead class="sticky">
            <tr>
              <th data-key="custody_code" @click="${this.sortClick}">
                Investeringskonto
              </th>
              <th data-key="custody_owner" @click="${this.sortClick}">Ejer</th>
              <th data-key="custody_name" @click="${this.sortClick}">
                Kontonavn
              </th>
              <th data-key="order_type" @click="${this.sortClick}">Type</th>
              <th data-key="source" @click="${this.sortClick}">Kilde</th>
              <th data-key="message" @click="${this.sortClick}">Besked</th>
              <th data-key="txt_reg_date" @click="${this.sortClick}">
                Tidspunkt
              </th>
              <th data-key="order_status" @click="${this.sortClick}">Status</th>
              <th
                class="numeric"
                data-key="amountqc"
                @click="${this.sortClick}"
              >
                Beløb
              </th>
            </tr>
          </thead>
          <tbody>
            ${this.orders.map(
							(row) => html`
                <tr>
                  <td style="white-space: nowrap;">
                    <a
                      data-custody-id="${row.custody_id}"
                      href="#"
                      @click="${this.custodyClick}"
                      >${row.custody_code}</a
                    >
                  </td>
                  <td>
                    <a
                      data-custody-id="${row.custody_id}"
                      href="#"
                      @click="${this.personClick}"
                      >${row.custody_owner}</a
                    >
                  </td>
                  <td>${row.custody_name}</td>
                  <td>${row.order_type} ${row.subtrantype}</td>
                  <td>${row.source}</td>
                  <td>${row.message}</td>
                  <td>${row.txt_reg_date}</td>
                  <td>${row.order_status}</td>
                  <td class="numeric">${
										row.amountqc ? money(row.amountqc) : null
									}</td>
                </tr>
              `,
						)}
          </tbody>
        </table>
      </div> `
						: null
				}
      </div>
    `;
	}

	async custodyClick(event: Event) {
		event.preventDefault();

		const custody_id = (event.target as HTMLAnchorElement).getAttribute(
			"data-custody-id",
		);

		const custodyResponse = await get<{ data: Custody }>(
			`/custodies/${custody_id}`,
		);

		if (!custodyResponse.ok) {
			const error = await custodyResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		const custody = custodyResponse.value.data;

		router.push(
			`/customers/${custody.client_id}/persons/${custody.owner_id}/custodies/${custody_id}`,
		);
	}

	async personClick(event: Event) {
		event.preventDefault();

		const custody_id = (event.target as HTMLAnchorElement).getAttribute(
			"data-custody-id",
		);

		const custodyResponse = await get<{ data: Custody }>(
			`/custodies/${custody_id}`,
		);

		if (!custodyResponse.ok) {
			const error = await custodyResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		const custody = custodyResponse.value.data;

		router.push(`/customers/${custody.client_id}/persons/${custody.owner_id}`);
	}

	renderSum() {
		let subtotal = 0;
		let inflow = 0;
		let outflow = 0;

		for (let i = 0; i < this.orders.length; i++) {
			inflow +=
				this.orders[i].order_type === "Bankoverførsel"
					? this.orders[i].amountqc
					: 0;
			outflow -=
				this.orders[i].order_type === "Salg" &&
				this.orders[i].subtrantype === ""
					? this.orders[i].amountqc
					: 0;
			subtotal += this.orders[i].amountqc;
		}

		return html`
      <div class="sums">
        <table class="tight sums">
          <tr>
            <td>Inflow</td>
            <td class="numeric" style="color: #006837">+${money(inflow)}</td>
          </tr>
          <tr>
            <td>Outflow</td>
            <td class="numeric" style="color: #a50026">${money(outflow)}</td>
          </tr>
          <tr>
            <td><strong>Nettoflow</strong></td>
            <td
              class="numeric"
              .style="${
								inflow + outflow >= 0 ? "color: #006837" : "color: #a50026"
							}"
            >
              <strong
                >${inflow + outflow >= 0 ? "+" : ""}${money(inflow + outflow)}</strong
              >
            </td>
          </tr>
          <tr>
            <td>Øvrige</td>
            <td class="numeric">
              ${money(subtotal - (inflow + Math.abs(outflow)))}
            </td>
          </tr>
        </table>
      </div>
    `;
	}

	connectedCallback() {
		super.connectedCallback();
		store.dispatch(setTitle("Handelsordrer"));
	}

	async search() {
		const ordersResponse = await get<{ data: Order[] }>(
			`/orders?fromdate=${this.fromDate}&todate=${this.toDate}&query=${this.query}&group=${this.group}`,
		);

		if (!ordersResponse.ok) {
			const error = await ordersResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		this.orders = ordersResponse.value.data;
	}

	sortClick(event: Event) {
		const key = (event.target as HTMLTableCellElement).getAttribute("data-key");

		if (!key) {
			return;
		}

		this.orders = sort(this.orders, key) as Order[];
		this.requestUpdate();
	}

	async refresh(event: Event) {
		this.loading = true;
		await this.search();
		this.loading = false;
	}

	async download(event: Event) {
		this.downloading = true;
		await this.search();

		const csvHeaders = [
			"custody_name",
			"custody_code",
			"custodytype_code",
			"custody_owner",
			"txt_reg_date",
			"order_id",
			"amountqc",
			"requestamountqc",
			"source",
			"message",
			"status_code",
			"order_status",
			"subtrantype",
			"order_type",
			"groups",
		];

		const csvFields = [
			"custody_name",
			"custody_code",
			"custodytype_code",
			"custody_owner",
			"txt_reg_date",
			"order_id",
			"amountqc",
			"requestamountqc",
			"source",
			"message",
			"status_code",
			"order_status",
			"subtrantype",
			"order_type",
			"groups",
		];

		const txtdata = btoa(toCsv(csvHeaders, csvFields, this.orders));

		const a = document.createElement("a");
		a.setAttribute("href", `data:text/csv;base64,${txtdata}`);
		a.setAttribute("download", "fm_orders.csv");
		a.click();
		this.downloading = false;
	}
}

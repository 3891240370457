import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { sort, toast } from "../../../utils.js";
import { money } from "../../../formatting/numberformats.js";
import { get } from "../../../api/client.js";
import { Order } from "../../../types/types.js";

declare global {
	interface HTMLElementTagNameMap {
		"custody-orders-view": typeof CustodyOrdersView;
	}
}

@customElement("custody-orders-view")
export default class CustodyOrdersView extends LitElement {
	@property({ type: Number })
	accessor custodyId: number | undefined;

	@property({ type: Number })
	accessor limit: number | undefined;

	@state()
	loading: boolean = false;

	@state()
	orders: Order[] = [];

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css` .card-block p {
    padding: 12px 24px;
  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <h2>
            ${
							this.limit
								? html`Seneste ${this.limit} handelsordrer`
								: html`Handelsordrer`
						}
          </h2>
        </div>
        <div class="card-block">
          ${
						this.loading
							? html`<p>Indlæser handelsordrer...</p>`
							: html` <table>
                <thead class="sticky">
                  <tr>
                    <th data-key="txt_reg_date" @click="${this.sortClick}">
                      Tidspunkt
                    </th>
                    <th data-key="source" @click="${this.sortClick}">Kilde</th>
                    <th data-key="message" @click="${this.sortClick}">
                      Besked
                    </th>
                    <th
                      class="numeric"
                      data-key="amountqc"
                      @click="${this.sortClick}"
                    >
                      Beløb
                    </th>
                    <th data-key="order_type" @click="${this.sortClick}">
                      Type
                    </th>
                    <th data-key="order_status" @click="${this.sortClick}">
                      Status
                    </th>
                  </tr>
                </thead>

                ${
									this.orders.length === 0
										? html`
                      <tbody>
                        <tr>
                          <td colspan="6">Ingen handelsordrer</td>
                        </tr>
                      </tbody>
                    `
										: html`<tbody>
                        ${this.orders.map(
													(row) => html`
                            <tr>
                              <td>${row.txt_reg_date}</td>
                              <td>${row.source}</td>
                              <td>${row.message}</td>
                              <td class="numeric">${money(row.amountqc)}</td>
                              <td>${row.order_type} ${row.subtrantype}</td>
                              <td>${row.order_status}</td>
                            </tr>
                          `,
												)}
                      </tbody>
                      <tfoot>
                        ${this.renderSum()}
                      </tfoot>`
								}
              </table>`
					}
        </div>
      </div>
    `;
	}

	renderSum() {
		let subtotal = 0;
		for (let i = 0; i < this.orders.length; i++) {
			subtotal += this.orders[i].amountqc;
		}

		return html`
      <tr>
        <td><strong>Total</strong></td>
        <td colspan="2"></td>
        <td class="numeric"><strong>${money(subtotal)}</strong></td>
        <td colspan="2"></td>
      </tr>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();

		this.loading = true;
		const ordersResponse = await get<{ data: Order[] }>(
			`/custodies/${this.custodyId}/orders${
				this.limit ? `?limit=${this.limit}` : ""
			}`,
		);
		this.loading = false;

		if (!ordersResponse.ok) {
			const error = await ordersResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return Promise.reject();
		}
		this.orders = ordersResponse.value.data;
	}

	sortClick(event: Event) {
		const key = (event.target as HTMLTableCellElement).getAttribute("data-key");

		if (!key) {
			return;
		}

		this.orders = sort(this.orders, key) as Order[];
		this.requestUpdate();
	}
}

import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import { Model, ModelData, ModelDataDetails } from "../../types/types.js";
import { toast } from "../../utils.js";
import { del, get, post, put } from "../../api/client.js";
import router from "../../router/index.js";
import { live } from "lit/directives/live.js";
import { AutocompleteElement } from "../../components/fm-autocomplete.js";

declare global {
	interface HTMLElementTagNameMap {
		"model-view": typeof ModelView;
	}
}

@customElement("model-view")
export default class ModelView extends LitElement {
	@property({ type: Number })
	accessor modelId: number | undefined;

	@state()
	model?: Model;

	@state()
	modelData: ModelData[] = [];

	@state()
	newModelData: {
		asofdate: string;
		secshare: number;
		security: string;
	} = { asofdate: "", secshare: 0, security: "" };

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`div.actions {
		display: flex;
		flex-grow: 0;
		flex-direction: row;
		margin-left: auto;
		align-items: flex-start;
	  }

	  .row-buttons {
		display: flex;
		flex-direction: row;
		gap: 16px;
		width: 100%;
	  }`,
	];
	render() {
		if (!this.model) {
			return;
		}

		const _model = this.model;

		return html`
      <div class="card">
        <div class="card-header">
          <h1>Modelportefølje</h1>
          <div class="actions">
            <fm-button-v2 class="button-small" type="button" @click="${
							this.copy
						}"
              >Kopiér</fm-button-v2
            >
            <fm-button-v2
              class="button-small"
              type="button"
              @click="${this.delete}"
              >Slet</fm-button-v2
            >
          </div>
        </div>
        <fm-form
          id="modelform"
          class="form-grid"
          url="/models/${this.modelId}"
          method="put"
        >
          <div class="form-field">
            <label for="code">
              Kode
              <input
                type="text"
                name="code"
                id="code"
                value="${live(this.model.code) || ""}"
                required
				@input="${(e: InputEvent) => {
					_model.code = (e.target as HTMLInputElement).value;
				}}"
              />
            </label>
          </div>
          <div class="form-field">
            <label for="name">
              Navn
              <input
                type="text"
                name="name"
                id="name"
                value="${live(this.model.name) || ""}"
                required
				@input="${(e: InputEvent) => {
					_model.name = (e.target as HTMLInputElement).value;
				}}"
              />
            </label>
          </div>
          <div class="form-field">
            <fm-button-v2
              type="button"
              class="btn btn--light"
              @click="${this.save}"
              >Gem</fm-button-v2
            >
          </div>
        </fm-form>
        <table>
          <thead>
            <tr>
              <th>Dato (fra)</th>
              <th>Investeringsbevis</th>
              <th class="numeric">Andel (%)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            ${this.modelNew()}
            ${(this.modelData || []).map((r, ix) => this.modelBlock(r, ix))}
          </tbody>
        </table>
      </div>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();

		if (this.modelId) {
			await this.refreshModel();

			await this.refreshModelData();
		}
	}

	modelNew() {
		return html`
      <tr>
        <td class="separate">
          <input
            class="inline right"
            type="text"
            placeholder="YYYY-MM-DD"
			@input="${(e: InputEvent) => {
				this.newModelData.asofdate = (e.target as HTMLInputElement).value;
			}}"
          />
        </td>
        <td class="separate">
          <fm-autocomplete
            url="/lov/fmfunds"
            select="security"
            display="name"
			@select="${(e: InputEvent) => {
				this.newModelData.security = (e.target as HTMLInputElement).value;
			}}"
          >
          </fm-autocomplete>
        </td>
        <td class="separate">
          <input
            class="inline right"
            type="text"
			@input="${(e: InputEvent) => {
				this.newModelData.secshare = Number.parseFloat(
					(e.target as HTMLInputElement).value.replaceAll(",", "."),
				);
			}}"
          />
        </td>
        <td class="separate">
          <div class="row-buttons">
            <fm-button-v2
              type="button"
              class="button-small"
              @click="${this.rowAdd}"
              >Tilføj</fm-button-v2
            >
          </div>
        </td>
      </tr>
    `;
	}

	modelRow(r: ModelDataDetails, ix1: number, ix2: number) {
		return html`
      <tr>
        <td>
          <input
            class="inline right"
            type="text"
            value="${r.asofdate || ""}"
            data-index1="${ix1}"
            data-index2="${ix2}"
            @input="${(e: InputEvent) => {
							this.modelData[ix1].details[ix2].asofdate = (
								e.target as HTMLInputElement
							).value;
						}}"
          />
        </td>
        <td>
          <fm-autocomplete
            url="/lov/fmfunds"
            .value="${{ code: r.security || "", name: r.security_name || "" }}"
            select="security"
            display="name"
            data-index1="${ix1}"
            data-index2="${ix2}"
            @select="${(e: Event) => {
							this.modelData[ix1].details[ix2].security = (
								e.target as AutocompleteElement
							).value;
						}}"
          >
          </fm-autocomplete>
        </td>
        <td>
          <input
            class="inline right"
            type="text"
            value="${r.secshare || ""}"
            data-index1="${ix1}"
            data-index2="${ix2}"
			@input="${(e: InputEvent) => {
				this.modelData[ix1].details[ix2].secshare = Number.parseFloat(
					(e.target as HTMLInputElement).value.replaceAll(",", "."),
				);
			}}"
          />
        </td>
        <td class="row-buttons">
          <fm-button-v2
            type="button"
            class="button-small"
            data-index1="${ix1}"
            data-index2="${ix2}"
            @click="${this.rowSave}"
          >
            Gem
          </fm-button-v2>
          <fm-button-v2
            type="button"
            class="button-small"
            data-index1="${ix1}"
            data-index2="${ix2}"
            @click="${this.rowDelete}"
          >
            Slet
          </fm-button-v2>
        </td>
      </tr>
    `;
	}

	modelShareSum(s: number) {
		if (s === 100) {
			return html` <td class="right-align">${s}</td>`;
		} else {
			return html` <td class="right-align red">${s}</td>`;
		}
	}

	modelBlock(r: ModelData, ix1: number) {
		return html`
      ${r.details.map((r, ix2) => this.modelRow(r, ix1, ix2))}
      <tr>
        <td class="right-align">${r.asofdate}</td>
        <td></td>
        ${this.modelShareSum(r.sharesum)}
        <td></td>
      </tr>
    `;
	}

	async rowSave(event: Event) {
		const target = event.target as HTMLButtonElement;
		const index1String = target.getAttribute("data-index1");
		const index2String = target.getAttribute("data-index2");

		if (
			!index1String ||
			!index2String ||
			!Number.parseInt(index1String) ||
			!Number.parseInt(index2String)
		) {
			return;
		}

		const index1 = Number.parseInt(index1String);
		const index2 = Number.parseInt(index2String);

		const row = this.modelData[index1].details[index2];

		const saveModelDataResponse = await put<typeof row, ModelData>(
			`/models/${this.modelId}/data/${row.id}`,
			row,
		);

		if (!saveModelDataResponse.ok) {
			const error = await saveModelDataResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		await this.refreshModelData();
	}

	async rowDelete(event: Event) {
		const target = event.target as HTMLButtonElement;
		const index1String = target.getAttribute("data-index1");
		const index2String = target.getAttribute("data-index2");

		if (
			!index1String ||
			!index2String ||
			!Number.parseInt(index1String) ||
			!Number.parseInt(index2String)
		) {
			return;
		}

		const index1 = Number.parseInt(index1String);
		const index2 = Number.parseInt(index2String);

		const row = this.modelData[index1].details[index2];

		const deleteModelDataResponse = await del(
			`/models/${this.modelId}/data/${row.id}`,
			{},
		);

		if (!deleteModelDataResponse.ok) {
			const error = await deleteModelDataResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		await this.refreshModelData();
	}

	async rowAdd(event: Event) {
		if (!this.modelId) {
			return;
		}

		const newModelDataResponse = await post<
			typeof this.newModelData,
			{ status: string; message: string }
		>(`/models/${this.modelId}/data`, this.newModelData);

		if (!newModelDataResponse.ok) {
			const error = await newModelDataResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		await this.refreshModelData();
	}

	async copy(event: Event) {
		const copyModelResponse = await get<Model>(`/models/${this.modelId}/copy`);

		if (!copyModelResponse.ok) {
			const error = await copyModelResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		// This will be a model because of the check above
		const newModel: Model = copyModelResponse.value as Model;
		this.modelId = newModel.id;
		this.model = newModel;

		await this.refreshModel();
		await this.refreshModelData();

		router.push(`/models/${newModel.id}`);
		toast("Modelportefølje kopieret");
	}

	async delete(event: Event) {
		const deleteModelResponse = await del(`/models/${this.modelId}`, {});

		if (!deleteModelResponse.ok) {
			const error = await deleteModelResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		router.push("/models");
		toast("Modelportefølje slettet");
	}

	async save(event: Event) {
		event.preventDefault();

		if (!this.modelId || !this.model) {
			return;
		}

		const setModelResponse = await put<
			{ code: string; name: string },
			{ data: Model }
		>(`/models/${this.modelId}`, {
			code: this.model?.code,
			name: this.model?.name,
		});

		if (!setModelResponse.ok) {
			const error = await setModelResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		toast("Modelportefølje gemt");

		await this.refreshModel();
	}

	async refreshModel() {
		const modelResponse = await get<{ data: Model }>(`/models/${this.modelId}`);

		if (!modelResponse.ok) {
			const error = await modelResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		this.model = modelResponse.value.data;

		this.requestUpdate();
	}

	async refreshModelData() {
		const modelDataResponse = await get<{ data: ModelData[] }>(
			`/models/${this.modelId}/data`,
		);

		if (!modelDataResponse.ok) {
			const error = await modelDataResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		this.modelData = modelDataResponse.value.data;

		this.requestUpdate();
	}
}

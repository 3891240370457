import { html, css } from "lit";

export const styles = css`
    .form-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 24px;
    }

    .reportform input,
    label,
    fm-button,
    fm-button-v2 {
      display: block;
    }

    .form-space {
      float: left;
      margin: 10px;
    }

    .form-grid .form-field {
      width: calc(50% - 8px);
    }

    .form-grid .form-field3 {
      width: calc(31% - 8px);
    }

    .form-grid .form-field4 {
      width: calc(24% - 8px);
    }

    .form-grid .form-field5 {
      width: calc(18% - 8px);
    }

    .form-grid .btn {
      width: 100%;
      margin-top: 8px;
    }

    .form-field {
      display: block;
      padding: 16px 0;
      font-size: 14px;
      font-weight: 500;
    }

    .form-space input[type="text"],
    .form-space input[type="number"],
    .form-space input[type="password"],
    .form-space input[type="date"],
    .form-space input[type="email"],
    .form-space input[type="tel"] {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      border: 1px solid #ddd;
      padding: 8px;
      margin-top: 4px;
      width: 100%;
      height: 38px;
      min-width: 140px;
    }

    .search-input {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      border: 1px solid #ddd;
      padding: 8px;
      margin-top: 4px;
      width: 100%;
      height: 38px;
      width: 100%;
      min-width: 200px;
    }

    .search-input-big {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      border: 1px solid #ddd;
      padding: 8px;
      margin-top: 4px;
      width: 100%;
      height: 38px;
      width: 100%;
      min-width: 400px;
    }

    .form-field input[type="text"],
    .form-field input[type="number"],
    .form-field input[type="password"],
    .form-field input[type="date"],
    .form-field input[type="email"],
    .form-field input[type="tel"] {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      border: 1px solid #ddd;
      padding: 8px;
      margin-top: 4px;
      width: 100%;
      height: 38px;
      min-width: 140px;
    }

    input.inline {
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      border: 1px solid #ddd;
      padding: 8px;
      width: 100%;
      height: 38px;
    }

    input.right {
      text-align: right;
    }

    .disabled-label {
      color: #888;
      pointer-events: none;
    }

    .form-field input[type="checkbox"] {
      margin: 8px;
    }

    .form-field select {
      width: 100%;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      padding: 8px;
      margin-top: 4px;
      border: 1px solid #ddd;
    }

    input[data-invalid="true"],
    *[data-invalid="true"] {
      border: 1px solid #f44336;
    }

    .form-field .field-error {
      display: none;
      color: #f44336;
      padding: 8px 0;
    }

    .form-field *[data-invalid="true"] + .field-error {
      display: block;
    }

    .form-grid .panel {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .text-input {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      border: 1px solid #ddd;
      padding: 8px;
      width: 100%;
    }

    input[type="checkbox"] {
      height: auto;
    }

    input:required:hover {
      opacity: 1;
    }

    .button {
      background: var(--primary-color);
      color: white;
      border: none;
      padding: 6px 6px;
      min-width: 100px;
      font-size: 14px;
      font-weight: 500;
      font-family: Roboto, sans-serif;
      text-transform: uppercase;
      text-align: center;
      transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
    .submit {
      border: 1px solid black;
    }

    .button[disabled] {
      background: #ddd;
      color: #666;
      cursor: default;
      user-select: none;
    }

    .button:not([disabled]) {
      cursor: pointer;
    }

    .button:not([disabled]):hover,
    .button:focus {
      opacity: 0.8;
    }

    .button-small {
      padding: 8px 12px;
      font-size: 10px;
      text-align: center;
    }

    .button-xsmall {
      padding: 4px 8px;
      font-size: 8px;
      min-width: initial;
    }

    .suggest-dropdown {
      position: absolute;
      display: none;
      z-index: 2;
    }

    .suggest-dropdown,
    .suggest-list {
      margin: 0;
      padding: 0;
      border: 1px solid #000;
      width: 400px;
      overflow: hidden;
      text-align: left;
    }

    .warning {
      background: yellow;
    }

    label {
      font-size: 12px;
    }
`;

export default html`<style>${styles}</style>`;

import { LitElement, html, css } from "lit";
import * as Sentry from "@sentry/browser";
import { toast } from "../../utils.js";
import { get } from "../../api/client.js";
import { connect } from "../../store/index.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import { sort } from "../../utils.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { cprNumber } from "../../formatting/piiformats.js";
import { User } from "../../types/types.js";
import { state } from "lit/decorators.js";

declare global {
	interface HTMLElementTagNameMap {
		"customers-view": typeof ConnectedElement;
	}
}

export class CustomersView extends LitElement {
	@state()
	users: User[] = [];

	@state()
	loading: boolean = false;

	@state()
	onlyRealOwners: boolean = false;

	@state()
	onlyEngagementOwners: boolean = false;

	@state()
	showPII?: boolean = undefined;

	toggleOnlyRealOwners() {
		this.onlyRealOwners = !this.onlyRealOwners;
	}

	toggleOnlyEngagementOwners() {
		this.onlyEngagementOwners = !this.onlyEngagementOwners;
	}

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`.card {
      padding: 12px;
    }

    #parms {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
    }

    div.search {
      display: flex;
      flex-direction: column;
    }

    div.search div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    div.search div.filters div {
      gap: 4px;
      margin-top: 8px;
    }

    div.actions {
      display: flex;
      flex-grow: 0;
      flex-direction: row;
      margin-left: auto;
      align-items: flex-start;
    }

    tr.odd {
      background-color: #eee;
    }

    tr.even {
      background-color: #fff;
    }`,
	];

	render() {
		return html`
      <div class="card" style="overflow: none;">
        <fm-form
          id="parms"
          class="reportform no-print"
          method="get"
          @submit="${this.searchClick}"
        >
          <div class="search">
            <div class="query">
              <input
                type="search"
                class="search-input-big"
                id="query"
                name="query"
                placeholder="Søg efter engagement eller person"
              />
              <fm-button-v2 id="submit_button" class="button submit" type="submit" .loading="${
								this.loading
							}"
                >Søg</fm-button-v2
              >
            </div>
            <div class="filters">
              <div class="real-owners">
                <input
                  type="checkbox"
                  id="only-real-owners"
                  name="only-real-owners"
                  .checked="${this.onlyRealOwners}"
                  @click="${this.toggleOnlyRealOwners}"
                />
                <label for="only-real-owners">Vis kun reelle ejere</label>
              </div>
              <div class="engagement-owners">
                <input
                  type="checkbox"
                  id="only-engagement-owners"
                  name="only-engagement-owners"
                  .checked="${this.onlyEngagementOwners}"
                  @click="${this.toggleOnlyEngagementOwners}"
                />
                <label for="only-engagement-owners"
                  >Vis kun ejere af engagementer</label
                >
              </div>
            </div>
          </div>
        </fm-form>

        <table class="${this.onlyEngagementOwners ? "" : "tight"}">
          <thead class="sticky">
            <tr>
              <th data-key="client_name" @click="${this.sortClick}">
                Engagement
              </th>
              <th data-key="name" @click="${this.sortClick}">Person</th>
              <th data-key="email" @click="${this.sortClick}">Email</th>
              <th data-key="cpr" @click="${this.sortClick}">CPR</th>
              <th data-key="cvr" @click="${this.sortClick}">CVR</th>
              <th data-key="address1" @click="${this.sortClick}">Adresse</th>
              <th data-key="usertype_code" @click="${this.sortClick}">Type</th>
              <th data-key="realowner_count" @click="${this.sortClick}">
                Reel ejer
              </th>
              <th data-key="reg_date" @click="${this.sortClick}">Oprettet</th>
            </tr>
          </thead>
          <tbody>
            ${
							this.loading
								? html`<tr>
                  <td colspan="9">Indlæser kunder...</td>
                </tr>`
								: this.renderUsers()
						}
          </tbody>
        </table>
      </div>
    `;
	}

	async search(query: string) {
		try {
			this.loading = true;
			const users = await get<{ data: User[] }>(`/users?query=${query}`);
			this.loading = false;

			if (users.ok === false) {
				const error = await users.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			this.users = users.value.data;
		} catch (err) {
			Sentry.captureException(err);
			console.error(err);
			this.loading = false;
			if (err instanceof Response) {
				try {
					const response = await err.json();
					toast(response.message || "Der er sket en fejl.");
				} catch (_) {}
			}
		}
	}

	async searchClick(event: Event) {
		const query = (this.shadowRoot?.getElementById("query") as HTMLInputElement)
			.value;

		await this.search(query);
	}

	sortClick(event: Event) {
		const key = (event.target as HTMLTableCellElement).getAttribute("data-key");

		if (!key) {
			return;
		}

		this.users = sort(this.users, key) as User[];
		this.requestUpdate();
	}

	renderUsers() {
		let clientIdCount = 0;

		if (!this.users) {
			return;
		}

		return this.users.map((user, index) => {
			// Figure out if we need to render a new client and visually separate
			const previousUser = this.users[index - 1];
			const isNewGroup =
				!previousUser || previousUser.client_id !== user.client_id;

			clientIdCount += isNewGroup ? 1 : 0;

			return html`${
				(!this.onlyRealOwners && !this.onlyEngagementOwners) ||
				(this.onlyRealOwners && user.realowner_count !== 0) ||
				(this.onlyEngagementOwners && user.pid)
					? html`
            <tr class="${clientIdCount % 2 ? "odd" : "even"}">
              <td>
                ${
									isNewGroup || this.onlyRealOwners || this.onlyEngagementOwners
										? html` <router-link to="/customers/${user.client_id}">
                      <a href="/customers/${user.client_id}"
                        >${user.client_name}</a
                      >
                    </router-link>`
										: null
								}
              </td>
              <td>
                <router-link
                  to="/customers/${user.client_id}/persons/${user.id}"
                >
                  <a href="/customers/${user.client_id}/persons/${user.id}"
                    >${user.name}</a
                  >
                </router-link>
              </td>
              <td>${user.email}</td>
              <td>${cprNumber(user.cpr)}</td>
              <td>${user.cvr}</td>
              <td>
                ${user.address1 ? `${user.address1}<br />` : ""}
                ${user.zipcity ? `${user.zipcity}<br />` : ""}
                ${user.country_name || ""}
              </td>
              <td>${user.usertype_code}</td>
              <td>${user.realowner_count === 0 ? "Nej" : "Ja"}</td>
              <td>
                ${
									user.reg_date
										? DateFormatter.format(
												new Date(user.reg_date),
												"dd-mm-yyyy",
										  )
										: ""
								}
              </td>
            </tr>
          `
					: ""
			}`;
		});
	}
}

const ConnectedElement = connect(CustomersView, {
	selectors: (state) => ({
		showPII: state.showPII,
	}),
}) as typeof CustomersView;

customElements.define("customers-view", ConnectedElement);

export default ConnectedElement;

const moneyFormatter = new Intl.NumberFormat("da-DK", {
	useGrouping: true,
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

export const money = (n: number | string) => {
	const parsedN = typeof n === "string" ? parseFloat(n) : n;
	return isNaN(parsedN) ? "" : moneyFormatter.format(parsedN);
};

const quantityFormatter = new Intl.NumberFormat("da-DK", {
	useGrouping: true,
	minimumFractionDigits: 6,
	maximumFractionDigits: 6,
});

export const quantity = (n: number | string) => {
	const parsedN = typeof n === "string" ? parseFloat(n) : n;
	return isNaN(parsedN) ? "" : quantityFormatter.format(parsedN);
};

const priceFormatter = new Intl.NumberFormat("da-DK", {
	useGrouping: true,
	minimumFractionDigits: 6,
	maximumFractionDigits: 6,
});

export const price = (n: number | string) => {
	const parsedN = typeof n === "string" ? parseFloat(n) : n;
	return isNaN(parsedN) ? "" : priceFormatter.format(parsedN);
};

const numberFormats = new Map();
export class NumberFormatter {
	/**
	 * Format a number.
	 * @param {number} value
	 * @param {number} decimals
	 */
	static format(value: number, decimals: number) {
		if (value == null) {
			return "";
		} else {
			let formatter = numberFormats.get(decimals);
			if (!formatter) {
				formatter = this.addFormat(decimals);
			}
			return formatter.format(value);
		}
	}

	static addFormat(decimals: number) {
		const formatter = new Intl.NumberFormat("da-dk", {
			useGrouping: true,
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals,
		});
		numberFormats.set(decimals, formatter);
		return formatter;
	}
}

import * as Sentry from "@sentry/browser";
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { get } from "../../api/client.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import { date } from "../../formatting/dateformats.js";
import { quantity, money } from "../../formatting/numberformats.js";
import {
	ClientPortfolio,
	ClientPortfolioTaxCalculation,
	ClientPortfolioTaxCalculationPosition,
	ClientPortfolioTaxCalculationTransaction,
	TaxType,
} from "../../types/types.js";
import { toast } from "../../utils.js";

declare global {
	interface HTMLElementTagNameMap {
		"customers-portfolio-tax-report-view": typeof CustomersPortfolioTaxReportView;
	}
}

@customElement("customers-portfolio-tax-report-view")
export default class CustomersPortfolioTaxReportView extends LitElement {
	@state()
	portfolios: ClientPortfolio[] = [];

	@state()
	taxtypes: TaxType[] = [];

	@state()
	calculations: ClientPortfolioTaxCalculation[] = [];

	@state()
	calc_message: string | null = null;

	@state()
	report: "portfolios" | "tax" = "portfolios";

	@state()
	fromdate: string = date(new Date());

	@state()
	todate: string = date(new Date());

	@state()
	query: string = "";

	@state()
	strTaxtypes: string = "";

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`.t {
      border: 1px solid black;
      margin: 10px;
    }

    div.actions {
      display: flex;
      flex-grow: 0;
      flex-direction: row;
      margin-left: auto;
      align-items: flex-start;
    }

    div.card-header {
      align-items: flex-start;
    }

    div.card-header .info p {
      margin: 0px;
      color: #666;
    }

    fm-form {
      display: flex;
      align-items: flex-end;
      gap: 8px;
    }

    div.form-space {
      margin: 0px;
      min-width: initial;
    }

    div.form-space input[type="date"] {
      font-size: 12px;
      height: 28px;
    }
    div.form-space fm-button-v2.button-small {
      margin: 0px;
    }

    th {
      white-space: nowrap;
    }`,
	];
	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <div class="info">
            <h1>Porteføljeskatterapport</h1>
          </div>
          <div class="actions">
            <fm-form method="get" id="parms" @submit="${this.refresh}">
              <div class="form-space">
                <label>Fra</label>
                <input type="date" name="fromdate" id="fromdate" value="${
									this.fromdate
								}" />
              </div>
              <div class="form-space">
                <label>Til</label>
                <input type="date" name="todate" id="todate" value="${
									this.todate
								}" />
              </div>
              <div class="form-space">
                <label>Søg</label>
                <input type="text" name="query" id="query" value="${this.query}" />
              </div>
              <div class="form-space">
                <label> Skattetyper</label>
                ${this.taxtypes.map(
									(t) =>
										html` <label for="tax-type-${t.id}"
                      ><input
                        type="checkbox"
                        id="tax-type-${t.id}"
                        data-id="${t.id}"
                        ?checked=${t.selected === "Y"}
                        @change="${this.chkBox}"
                      />${t.name}</label
                    >`,
								)}
              </div>
              <div class="form-space">
                <label>Skattemåned</label>
                <select name="taxmonth" id="taxmonth">
                  <option value=""></option>
                  <option value="1">Januar</option>
                  <option value="2">Februar</option>
                  <option value="3">Marts</option>
                  <option value="4">April</option>
                  <option value="5">Maj</option>
                  <option value="6">Juni</option>
                  <option value="7">Juli</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">Oktober</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
              <div class="form-space">
                <fm-button-v2 type="submit" class="button submit">Søg</fm-button-v2>
              </div>
              <div class="form-space">
                <fm-button-v2 @click="${
									this.calculate
								}" class="button submit" ?disabled="${
			this.portfolios.length === 0
		}"
                  >Beregn</fm-button-v2
                >
              </div>
            </fm-form>
          </div>
        </div>
        ${this.renderMessage()} ${this.renderPortfolios()} ${this.renderTax()}
      </div>
    `;
	}

	renderMessage() {
		if (this.calc_message) {
			return html`<div class="card-block">
        <div>${this.calc_message}</div>
      </div>`;
		} else {
			return "";
		}
	}

	renderPortfolios() {
		if (this.report === "portfolios") {
			return html` <table>
        <thead>
          <tr>
            <th>Skattetype</th>
            <th>Kunde ID</th>
            <th>Investeringskonto</th>
            <th>Navn</th>
            <th>Skattemåned</th>
          </tr>
        </thead>
        <tbody>
          ${this.portfolios.map(
						(p) => html` <tr>
              <td>${p.taxtype}</td>
              <td>${p.client_code}</td>
              <td>${p.custody_code}</td>
              <td>${p.name}</td>
              <td>${p.taxmonth}</td>
            </tr>`,
					)}
        </tbody>
      </table>`;
		} else {
			return null;
		}
	}

	renderTax() {
		if (this.report === "tax") {
			return html`${this.calculations.map((c) => this.renderTaxReport(c))}`;
		} else {
			return null;
		}
	}

	renderTaxReport(r: ClientPortfolioTaxCalculation) {
		return html`
      <div class="card">
        <div class="card-header">
          <h2>${r.title}</h2>
        </div>
        <div class="card-block">${this.renderSummary(r)}</div>
        <div class="card-block">
          <table class="t">
            <tr>
              <th>Depot</th>
              <th>ISIN</th>
              <th>Navn</th>
              <th class="numeric">Beholdning</th>
              <th class="numeric">Kurs</th>
              <th class="numeric">Værdi, DKK</th>
            </tr>
            ${r.positions.map((p) => this.renderReportPosition(p))}
          </table>
        </div>
        <div class="card-block">
          <table class="t">
            <tr>
              <th>ISIN</th>
              <th>Navn</th>
              <th>Dato</th>
              <th>Type</th>
              <th>Depot</th>
              <th class="numeric">Antal</th>
              <th class="numeric">Kursværdi</th>
              <th class="numeric">Udbytte kap.</th>
              <th class="numeric">Udbytte akt.</th>
              <th class="numeric">Avance kap.</th>
              <th class="numeric">Avance akt.</th>
            </tr>
            ${r.transactions.map((p) => this.renderReportTransaction(p))}
          </table>
        </div>
      </div>
    `;
	}

	renderSummary(c: ClientPortfolioTaxCalculation) {
		if (c.taxtype === "FRI") {
			return html`${this.renderSummary1(c)}`;
		} else {
			return html`${this.renderSummary2(c)}`;
		}
	}

	renderSummary1(c: ClientPortfolioTaxCalculation) {
		const l_dividend_eq = c.transactions.reduce((a, t) => a + t.dividend_eq, 0);
		const l_dividend_cap = c.transactions.reduce(
			(a, t) => a + t.dividend_cap,
			0,
		);
		const l_pl_eq = c.transactions.reduce((a, t) => a + t.profit_loss_eq, 0);
		const l_pl_cap = c.transactions.reduce((a, t) => a + t.profit_loss_cap, 0);

		return html` <table class="t">
        <tr>
          <th>Kapitalindkomst</th>
          <th>Rubrik</th>
          <th class="numeric">Beløb</th>
        </tr>
        <tr>
          <td>Udbytte</td>
          <td>Rubrik 430</td>
          <td class="numeric">${money(l_dividend_cap)}</td>
        </tr>
        <tr>
          <td>Kursavance</td>
          <td>Rubrik 422</td>
          <td class="numeric">${money(l_pl_cap)}</td>
        </tr>
        <tr>
          <td>I alt</td>
          <td></td>
          <td class="numeric">${money(l_dividend_cap + l_pl_cap)}</td>
        </tr>
      </table>
      <table class="t">
        <tr>
          <th>Aktieindkomst</th>
          <th>Rubrik</th>
          <th class="numeric">Beløb</th>
        </tr>
        <tr>
          <td>Udbytte</td>
          <td>Rubrik 452</td>
          <td class="numeric">${money(l_dividend_eq)}</td>
        </tr>
        <tr>
          <td>Kursavance</td>
          <td>Rubrik 375</td>
          <td class="numeric">${money(l_pl_eq)}</td>
        </tr>
        <tr>
          <td>I alt</td>
          <td></td>
          <td class="numeric">${money(l_dividend_eq + l_pl_eq)}</td>
        </tr>
      </table>`;
	}

	renderSummary2(c: ClientPortfolioTaxCalculation) {
		const l_dividend_eq = c.transactions.reduce((a, t) => a + t.dividend_eq, 0);
		const l_dividend_cap = c.transactions.reduce(
			(a, t) => a + t.dividend_cap,
			0,
		);
		const l_pl_eq = c.transactions.reduce((a, t) => a + t.profit_loss_eq, 0);
		const l_pl_cap = c.transactions.reduce((a, t) => a + t.profit_loss_cap, 0);

		return html`
      <table class="t">
        <tr>
          <th>Investeringsselskaber</th>
          <th>Rubrik</th>
          <th class="numeric">Beløb</th>
        </tr>
        <tr>
          <td>Udbytte</td>
          <td>Rubrik 166</td>
          <td class="numeric">${money(l_dividend_cap + l_dividend_eq)}</td>
        </tr>
        <tr>
          <td>Kursavance</td>
          <td>Rubrik 116</td>
          <td class="numeric">${money(l_pl_cap + l_pl_eq)}</td>
        </tr>
        <tr>
          <td>I alt</td>
          <td></td>
          <td class="numeric">
            ${money(l_dividend_cap + l_pl_cap + l_dividend_eq + l_pl_eq)}
          </td>
        </tr>
      </table>
    `;
	}

	renderReportTransaction(p: ClientPortfolioTaxCalculationTransaction) {
		return html`
      <tr>
        <td>${p.security}</td>
        <td>${p.security_name}</td>
        <td>${p.transaction_date}</td>
        <td>${p.transaction_type}</td>
        <td>${p.custody_code}</td>
        <td class="numeric">${quantity(p.quantity)}</td>
        <td class="numeric">${money(p.buysell)}</td>
        <td class="numeric">${money(p.dividend_cap)}</td>
        <td class="numeric">${money(p.dividend_eq)}</td>
        <td class="numeric">${money(p.profit_loss_cap)}</td>
        <td class="numeric">${money(p.profit_loss_eq)}</td>
      </tr>
    `;
	}

	renderReportPosition(p: ClientPortfolioTaxCalculationPosition) {
		return html`
      <tr>
        <td>${p.custody_code}</td>
        <td>${p.security}</td>
        <td>${p.security_name}</td>
        <td class="numeric">${quantity(p.quantity)}</td>
        <td class="numeric">${quantity(p.price)}</td>
        <td class="numeric">${money(p.marketvalue_pc)}</td>
      </tr>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();

		const taxtypes = await get<{ data: TaxType[] }>("/lov/taxtypes");

		if (!taxtypes.ok) {
			return;
		}

		this.taxtypes = taxtypes.value.data;
	}

	chkBox(event: Event) {
		const target = event.target as HTMLInputElement;
		const targetId = target.getAttribute("data-id");

		if (!target || !targetId) {
			return;
		}

		const id = Number.parseInt(targetId);

		for (let i = 0; i < this.taxtypes.length; i++) {
			if (this.taxtypes[i].id === id) {
				if (target.checked) {
					this.taxtypes[i].selected = "Y";
				} else {
					this.taxtypes[i].selected = "N";
				}
			}
		}
	}

	async refresh() {
		const taxmonth = (
			this.shadowRoot?.getElementById("taxmonth") as HTMLSelectElement
		).value;
		const query = (this.shadowRoot?.getElementById("query") as HTMLInputElement)
			.value;

		const reducer = (a: string, t: TaxType) =>
			t.selected === "Y" ? `${a}${t.id},` : a;
		const types = this.taxtypes.reduce(reducer, ",");

		this.strTaxtypes = types;

		try {
			const portfolios = await get<{ data: ClientPortfolio[] }>(
				`/portfolios?types=${types}&taxmonth=${taxmonth}&query=${query}`,
			);

			if (!portfolios.ok) {
				const error = await portfolios.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			this.portfolios = portfolios.value.data;
			this.report = "portfolios";
		} catch (err) {
			Sentry.captureException(err);
			console.error(err);

			if (err instanceof Response) {
				try {
					const response = await err.json();
					toast(response.message || "Der er sket en fejl.");
				} catch (_) {}
			}
		}
	}

	async calculate() {
		const fromdate = (
			this.shadowRoot?.getElementById("fromdate") as HTMLInputElement
		).value;
		const todate = (
			this.shadowRoot?.getElementById("todate") as HTMLInputElement
		).value;

		this.calculations = [];

		try {
			for (let i = 0; i < this.portfolios.length; i++) {
				this.calc_message = `${i} af ${this.portfolios.length} : ${this.portfolios[i].custody_code}`;

				const calculation = await get<ClientPortfolioTaxCalculation>(
					`/portfolios/${this.portfolios[i].id}/dk-tax?fromdate=${fromdate}&todate=${todate}`,
				);

				if (!calculation.ok) {
					const error = await calculation.error.json();

					if ("message" in error) {
						toast(error.message);
					} else {
						toast("Der er sket en fejl.");
					}

					return;
				}

				this.calculations.push(calculation.value);
			}
		} catch (err) {
			Sentry.captureException(err);
			console.error(err);

			if (err instanceof Response) {
				try {
					const response = await err.json();
					toast(response.message || "Der er sket en fejl.");
				} catch (_) {}
			}
		} finally {
			this.calc_message = null;
			this.report = "tax";
		}
	}

	showreport() {
		this.report = "tax";
	}
}

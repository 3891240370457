import * as Sentry from "@sentry/browser";
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { get } from "../../api/client.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import "./create-group-view.js";
import { ClientGroup } from "../../types/types.js";
import { toast } from "../../utils.js";

declare global {
	interface HTMLElementTagNameMap {
		"groups-view": GroupsView;
	}
}

@customElement("groups-view")
export default class GroupsView extends LitElement {
	@state()
	loading: boolean = false;

	@state()
	openCreateGroupDialog: boolean = false;

	@state()
	groups: ClientGroup[] = [];

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`.card {
    padding: 12px;
  }

  .header {
    display: flex;
    flex-direction: row;
  }

  .actions {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-items: flex-start;
  }

  .actions .button-small {
    margin-left: auto;
    text-align: center;
    font-weight: bold;
  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="actions">
          <fm-button-v2
            class="button-small"
            type="button"
            @click="${this.createClick}"
            >Opret ny</fm-button-v2
          >
        </div>
        ${
					this.loading
						? html`<p>Indlæser kundegrupper...</p>`
						: html` <table>
              <thead>
                <tr>
                  <th>Kode</th>
                  <th>Navn</th>
                </tr>
              </thead>
              <tbody>
                ${
									this.groups.length > 0
										? this.groups.map(
												(group) => html`
                        <tr>
                          <td>
                            <router-link to="/groups/${group.id}">
                              <a>${group.code}</a>
                            </router-link>
                          </td>
                          <td>${group.name}</td>
                        </tr>
                      `,
										  )
										: html`<tr>
                      <td colspan="2">Ingen kundegrupper</td>
                    </tr>`
								}
              </tbody>
            </table>`
				}
      </div>
      <fm-dialog
        .opened="${this.openCreateGroupDialog}"
        @close="${() => {
					this.openCreateGroupDialog = false;
				}}"
      >
        ${
					this.openCreateGroupDialog
						? html`<create-group-view
              @created="${this.groupCreated}"
            ></create-group-view>`
						: null
				}
      </fm-dialog>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();
		this.groups = await this.getGroups();
	}

	createClick() {
		this.openCreateGroupDialog = true;
	}

	async groupCreated() {
		this.openCreateGroupDialog = false;
		this.groups = await this.getGroups();
	}

	async getGroups(): Promise<ClientGroup[]> {
		try {
			this.loading = true;
			const groups = await get<{ data: ClientGroup[] }>("/client-groups");

			if (!groups.ok) {
				const error = await groups.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return Promise.reject();
			}

			return groups.value.data;
		} catch (err) {
			Sentry.captureException(err);
			console.error(err);
			if (err instanceof Response) {
				try {
					const response = await err.json();
					toast(response.message || "Der er sket en fejl.");
				} catch (_) {}
			}
			return Promise.reject(err);
		} finally {
			this.loading = false;
		}
	}
}

import { LitElement, html, css, PropertyValues } from "lit";
import { state, property, customElement } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { DateFormatter } from "../../../formatting/dateformats.js";
import { money } from "../../../formatting/numberformats.js";
import "../../custodies/create-custody-view.js";
import { Client, Custody } from "../../../types/types.js";
import { get } from "../../../api/client.js";

declare global {
	interface HTMLElementTagNameMap {
		"person-custodies-view": typeof PersonCustodiesView;
	}
}

@customElement("person-custodies-view")
export default class PersonCustodiesView extends LitElement {
	@property({ type: Number })
	accessor clientId: number | undefined;

	@property({ type: Number })
	accessor userId: number | undefined;

	@state()
	custodies: Custody[] = [];

	@state()
	totalValue: number = 0;

	@state()
	openCreateCustodyDialog: boolean = false;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`#parms {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
	  }

	  #persons tr.first td {
		border-top: 2px solid #ddd;
	  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <h1>Investeringskonti</h1>
          <div class="actions">
            <fm-button-v2
              class="button-small"
              type="button"
              @click="${this.createClick}"
              >Opret ny</fm-button-v2
            >
          </div>
        </div>
        <table id="custodies">
          <thead class="sticky">
            <tr>
              <th>Kontonummer</th>
              <th>Type</th>
              <th>Kontonavn</th>
              <th>Modelportefølje</th>
              <th>Oprettet</th>
              <th class="numeric">Værdi</th>
            </tr>
          </thead>
          <tbody>
            ${
							this.custodies?.length === 0
								? html`<tr>
                  <td colspan="6">Ingen konti</td>
                </tr>`
								: html`${this.custodies.map(
										(custody, index) => html`
                      <tr class="${index === 0 ? "first" : ""}">
                        <td style="white-space: nowrap;">
                          <router-link
                            to="/customers/${custody.client_id}/persons/${
											custody.owner_id
										}/custodies/${custody.id}"
                          >
                            <a>${custody.custody_code}</a>
                          </router-link>
                        </td>
                        <td>${custody.custodytype_code}</td>
                        <td>${custody.name}</td>
                        <td>${custody.model_name} ${custody.model_status}</td>
                        <td style="white-space: nowrap;">
                          ${DateFormatter.format(
														new Date(custody.reg_date),
														"dd-mm-yyyy",
													)}
                        </td>
                        <td class="numeric">${money(custody.currentvalue)}</td>
                      </tr>
                    `,
								  )}
                  <tr>
                    <td colspan="5"><strong>I alt</strong></td>
                    <td class="numeric">
                      <strong>${money(this.totalValue)}</strong>
                    </td>
                  </tr>`
						}
          </tbody>
        </table>
      </div>
      <fm-dialog
        .opened="${this.openCreateCustodyDialog}"
        @close="${() => {
					this.openCreateCustodyDialog = false;
				}}"
      >
        ${
					this.openCreateCustodyDialog
						? html`<create-custody-view
              .userId="${this.userId}"
              @created="${this.custodyCreated}"
            ></create-custody-view>`
						: null
				}
      </fm-dialog>
    `;
	}

	async updated(changedProperties: PropertyValues<this>) {
		if (changedProperties.has("userId")) {
			await this.loadCustodies();
		}
	}

	async loadCustodies() {
		this.totalValue = 0;

		const clientResponse = await get<Client>(`/clients/${this.clientId}`);

		if (!clientResponse.ok) {
			return;
		}

		const client = clientResponse.value;

		const custodyPromises: Promise<Custody>[] = [];

		if (client.custodies) {
			for (const _custody of client.custodies) {
				const custodyPromise: Promise<Custody> = new Promise(
					// rome-ignore lint/suspicious/noAsyncPromiseExecutor: Intentional
					async (resolve, reject) => {
						const custodyResponse = await get<{ data: Custody }>(
							`/custodies/${_custody.id}`,
						);

						if (!custodyResponse.ok) {
							return;
						}

						return resolve(custodyResponse.value.data);
					},
				);
				custodyPromises.push(custodyPromise);
			}
		}

		const custodies = await Promise.all(custodyPromises);
		const userCustodies: Custody[] = [];

		// TODO: Not optimal, but it works
		for (const custody of custodies) {
			if (
				this.userId === custody.owner_id ||
				this.userId === custody.owner2_id
			) {
				userCustodies.push(custody);
				this.totalValue += custody.currentvalue ? custody.currentvalue : 0;
			}
		}

		this.custodies = userCustodies;

		this.requestUpdate();
	}

	createClick() {
		this.openCreateCustodyDialog = true;
	}

	async custodyCreated() {
		this.openCreateCustodyDialog = false;

		await this.loadCustodies();
	}
}
